
const UserRole = {
    Admin: "Super Admin",
    Manager: "Admin",
    Client: "Client",
}

const VehicleTypes = [
    { type: "Car", name: "Car" },
    { type: "Bike", name: "Bike" },
    { type: "ACS", name: "Access Controller" },
    { type: "Router", name: "Router" },
    { type: "Box", name: "Box" },
]

const isIgnoreVehicleType = (ignoreVehicleTypes, type) => {
    if (!ignoreVehicleTypes || ignoreVehicleTypes.length == 0) {
        return false;
    }
    const result = ignoreVehicleTypes?.findIndex(item => item == type);
    return result != -1;
}

const DeviceType = {
    Teltonika: "Teltonika",
    Ruptela: "Ruptela",
    Biosense: "Biosense",
}

const cmdType = {
    EngineOn: "EngineOn",
    DigitalOutput: "Digital Inputs/Outputs",
    DigitalInput: "Digital Input",
    BatteryLevel: "Battery Level",
    BatteryVoltage: "Battery Voltage",
    ExternalVoltage: "External Voltage",
    AnalogueInput: "Analogue Input",
    AxisX: "Axis X",
    AxisY: "Axis Y",
    AxisZ: "Axis Z",

    MotionDetection: "Motion Detection",
    SleepMode: "Sleep Mode",
    NetworkTimeProtocalServer: "Network Time Protocal Server",
    StaticNavigation: "Static Navigation",
    GnssSource: "GNSS Source",
    LedIndication: "LED Indication",
    BatteryChargeMode: "Battery charge mode",
    IgnitionDetectionSource: "Ignition Detection Source",
    AccelerometerCalibrationAndGravityFilter: "Accelerometer calibration and gravity filter",
    DataCodecToUseForDataTransmission: "Data codec to use for data transmission",
    GetverCommandResponse: "getver command response",
    AssistedGPSSettings: "Assisted GPS Settings",

    RecordsParameters: "Records Parameters",
    EnableConnectionOverTLS: "Enable connection over TLS",
    FirmwareOverTheAirWebService: "Firmware Over The Air web service",
    NetworkPing: "Network Ping",
    GprsServerSetup: "GPRS Server Setup",
    GprsSetup: "GPRS Setup",
    SecondaryGprsServerSetup: "Secondary GPRS Server Setup",
    MQTTSettings: "MQTT Settings",

    SmsReportSendingParams: "SMS report sending parameters",
    IncomingCallAction: "Incoming call action",
    PhoneNumbers: "Phone Numbers",
    SmsEventTimeZone: "SMS event Time Zone",
    AuthorizedNumbers: "Authorized Numbers",

    HomeGsmNetwork: "Home GSM Network",
    RoamingGsmNetworkSettings: "Roaming GSM Network Setting",
    UnknownGsmNetwork: "Unknown GSM Network",
    TripScenarioParam: "Trip Scenario Parameter",
    Odometer: "Odometer",
    TrackingOnDemandMode: "Tracking on Demand Mode",

    GreenDriving: "Green Driving",
    OverSpeeding: "OverSpeeding",
    DoutControlViaIgnition: "Dout Control Via Ignition",
    DoutControlViaCall: "Dout Control Via Call",
    IgnitionOnCounter: "Ignition ON Counter",
    NetworkJamming: "Network Jamming",
    GNSSJamming: "GNSS Jamming",
    IDReadNotification: "ID Read Notification",
    Immobilizer: "Immobilizer",
    SECO: "SECO",
    Dout1OutputType: "Dout1 Output Type",
    AuthorizedIDsList: "Authorized ID List",

    UnplugDetection: "Unplug Detection",
    TowingDetection: "Towing Detection",
    CrashDetection: "Crash Detection",
    ExcessiveIdlingDetection: "Excessive Idling Detection",

    CanParam: "CAN Parameters",
    ToggleCanControlToOpenUnlockAllDoors: "Tolggle CAN-CONTROL to open unlock all doors",
    ToggleCanControlToOpenUnlockTrunk: "Tolggle CAN-CONTROL to open unlock trunk",
    ToggleCanControlToBlockEngine: "Toggle CAN-CONTROL to block engine",
    LVCANSetup: "LVCAN setup",
    ToggleCanControlToCloselockAllDoors: "Tolggle CAN-CONTROL to close lock all doors",
    ToggleCanControlToFlashLights: "Tolggle CAN-CONTROL to flash lights",
    ToggleCanControlToUnblockEngine: "Toggle CAN-CONTROL to unblock engine",

    BluetoothWorkMode: "Bluetooth work mode",
    BLEcommonSetting: "BLE Common Settings",
    AutoConnectToExternalDevice: "Auto Connect to external device",
    AuthorizedBeaconsList: "Authorized beacons list",
    BeaconSettings: "Beacon Settings",
    BLESerialEncryption: "BLE Serial Encryption",
    EYESensor: "EYE Sensor",

    ReqGPSDataTime: "Request current GPS Date and Time",
    FormatSDcard: "Format SD card",
    RestartDev: "Restart the device",
    ConnectToFotaWeb: "Connect to Fota Web immediately",
    ForceDevToGenReport: "Force device to Generate a report",
    GetimeiccidCmd: "Send getimeiccid command",

    NetworkMode: "Network mode",
    LoTNetworkMode: "loT Network Mode",
    BandSelectionMode: "Band Seletion Mode",

    Custom: "Custom"
}


const ReportTypes = [
    "Fuel",
    "Idle",
    "Connectivity",
    "Movement",
    "Ignition Status",
    "Ignition Control",
    "OverSpeed",
    "Crash",
    "Geofence",
    "GPS Status",
    "Low Battery",
    "Door Status",
    "Temperature",
    "Trip Odometer",
];

const RuleTypes = {
    BatteryLow: "Battery Low",
    Crash: "Crash Detected",
    DeviceStatus: "Device Status",
    DoorLockStatus: "Door Lock Status",
    DoorStatus: "Door Status",
    ExternalVoltage: "External Voltage",
    FuelStatus: "Fuel Status",
    GPSStatus: "GPS Status",
    Geofence: "Geofence",
    IgnitionControl: "Ignition Control",
    IgnitionStatus: "Ignition Status",
    Movement: "Movement",
    OverSpeed: "Over Speed",
    Stop: "Stop",
    Temperature: "Temperature Status",
    Towing: "Towing Detected",
    Custom: "Custom",
};

const IOPins = [
    "DIN1",
    "DIN2",
    "DIN3",
    "DIN4",
    "DOUT1",
    "DOUT2",
    "DOUT3",
    "DOUT4",
    "AIN1",
    "AIN2",
    "AIN3",
    "AIN4",
];


const CommandPriorities = [
    { value: "", label: "" },
    { value: "0", label: "Disabled" },
    { value: "1", label: "Low" },
    { value: "2", label: "High" },
    { value: "3", label: "Panic" }
];

const CommandDoutControls = [
    { value: "", label: "" },
    { value: "0", label: "None" },
    { value: "1", label: "DOUT1" },
    { value: "2", label: "DOUT2" },
    { value: "3", label: "DOUT3" },
];

const CommandOperands = [
    { value: "", label: "" },
    { value: "0", label: "On Exit" },
    { value: "1", label: "On Enter" },
    { value: "2", label: "On Both" },
    { value: "3", label: "Monitoring" },
    { value: "4", label: "On Hysteresis" },
    { value: "5", label: "On Change" },
    { value: "6", label: "On Delta Change" },
];

const CommandPhones = [
    "", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
];

const getCommandPhones = (gsmNumbers) => {
    if (gsmNumbers && gsmNumbers.length > 0) {
        return [{ value: "", label: "" }].concat(
            gsmNumbers.map((number, index) => ({
                value: index,
                label: number?.trim().length > 0 ? number : "-"
            }))
        );
    }
    return CommandPhones;
}

const ACSUserTypes = [
    "Normal", "Super", "Vistor", "Guard Touring", "Defense Card",
];
const ACSVerifyModes = [
    "None", "Card/Finger", "Card", "Finger", "Card+Finger",
];
const ACSLogInOutIndications = [
    "None", "IN", "OUT", "",
];

const ACSDoorSensorStatuses = [
    "Not Used", "Normal Open", "Normal Close", "Short Circuit", "Open Circuit", "Intruded", "Open too long", "Not Opened", "No Response"
];
const ACSSecurityStatuses = [
    "Force open", "Force Close", "Normal", "Not Used",
];

const ACSEventCodes = {
    '00': 'None',
    '01': 'Door open too long',
    '02': 'Door closed after alert',
    '03': 'By Pass On',
    '04': 'By Pass Off',
    '05': 'Back to Normal from By Pass',
    '06': 'Unauthorized User',
    '07': 'Unregistered User',
    '08': 'Deactivated User',
    '09': 'Expired User',
    '0a': 'Anti Pass Back Violation',
    '0b': 'Not Allowed Door',
    '0c': 'Door Intruded',
    '0d': 'Multi-Badge Violation',
    '0e': 'Tamper Switch Breakdown',
    '0f': 'Exit Button Pressed',
    '10': 'Door Normal Closed',
    '11': 'Duress Alarm On',
    '12': 'Fire Alarm On',
    '13': 'Defense On',
    '14': 'Defense Off',
    '15': 'Tamper Switch Closed',
    '16': 'Time Zone Violation',
    '17': 'Lock Forced Release Time Start',
    '18': 'Lock Forced Release Time End',
    '19': 'Warm Start',
    '1a': 'Cold Start',
    '1b': 'Backup Power',
    '1c': 'Normal Power',
    '1d': 'BF50 On',
    '1e': 'BF50 Off',
    '1f': 'Door Sensor short circuit',
    '20': 'Door Sensor open circuit',
    '21': 'Invalid Password',
    '22': 'Interlock Violation',
    '23': 'Emergency Open',
    '24': 'Emergency Close',
    '25': 'Fire Alarm Detection Enabled',
    '26': 'Fire Alarm Detection Disabled',
    '27': 'Door Normal Opened',
    '28': 'Turn Off Alarm Trigger Manually',
    '29': 'Turn Off Alarm Trigger Automatically',
    '2a': 'IP Conflict',
    '2b': 'Keypad is locked due to password error try',
    '2c': 'Keypad recover',
    '2d': 'Biosense Online for 670W',
    '2e': 'Biosense Offline for 670W',
    '2f': 'Pulse Open Door',
    '30': 'Exit Button Short Circuit',
    '31': 'Exit Button Open Circuit',
    '32': 'Fire Button Short Circuit',
    '33': 'Fire Button Open Circuit',
    '38': 'DEGRADE_ALL_PASS(Semac-D only)',
    '39': 'DEGRADE_FC_PASS(Semac-D only)',
    '3a': 'DEGRADE_REG_PASS(Semac-D only)',
    '3b': 'W Series FastReg',
    '3c': 'Fire alarm off',
    '3d': 'Black List',
    '3e': 'Reserved (BF333 Online only for S3V3)',
    '3f': 'Reserved (BF333 Offline only for S3V3)',
    '40': 'Reserved(Semac-D only)',
    '41': 'EMERGANCY_BUTTON_ENABLE (SEMAC only)',
    '42': 'EMERGANCY_BUTTON_DISABLE (SEMAC only)',
    '43': 'LIFT_REPAIR_ON(SEMAC only)',
    '44': 'LIFT_REPAIR_OFF(SEMAC only)',
    '45': 'BATTERY_OK(SEMAC only)',
    '46': 'BATTERY_BD(SEMAC only)',
    '47': 'OSDP_ONLINE',
    '48': 'OSDP_OFFLINE',
    '49': 'Temperature Abnormal',
    '4a': 'Temperature Online',
    '4b': 'Temperature Offline',
    '4c': 'Smoke Alarm',
    '4d': 'PIR Motion Alarm'
}

export {
    UserRole, VehicleTypes, DeviceType, 
    cmdType, ReportTypes, RuleTypes, IOPins,
    CommandPriorities, CommandOperands, CommandPhones, CommandDoutControls,
    ACSUserTypes, ACSVerifyModes, ACSLogInOutIndications, ACSDoorSensorStatuses, ACSSecurityStatuses, ACSEventCodes,
    getCommandPhones, isIgnoreVehicleType
};