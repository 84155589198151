import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { updateUser, uploadAvatar } from "../../../services/axios";

import { Select, notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { UserRole } from "../../../common/common";
import { getCountries } from "../../../utils/globals";
import { UPDATE_USER } from "../../../redux/store/types";
import { useMediaQuery } from "@mui/material";

const EditUser = () => {
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const getData = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const role = useSelector((state) => state.auth.user.role);
  const CountryData = getCountries();

  const [phoneNumber, setPhoneNumber] = useState(getData.state.mobile);
  const [avatarPath, setAvatarPath] = useState(getData.state.image);
  const [formData, setFormData] = useState({
    id: getData.state.id,
    address: getData.state.address,
    name: getData.state.name,
    username: getData.state.username,
    email: getData.state.email,
    mobile: getData.state.mobile,
    country: getData.state.country,
    company: getData.state.company,
    role: getData.state.role,
  })

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input your name!";
    } else if (formData.username.length === 0) {
      msg = "Please input your username!";
    } else if (formData.email.length === 0) {
      msg = "Please input your email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input your mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input your country";
    } else if (formData.address.length === 0) {
      msg = "Please input your address!";
    } else if (formData.role.length === 0) {
      msg = "Please select your role!";
    } else if (formData.company?.length === 0) {
      msg = "Please select your company!";
    }
    if (msg != null) {
      notification.warning({
        placement: "topRight",
        description: msg,
        duration: 3,
      });
      return;
    }
    final()
  }

  const final = async () => {
    const sendData = {
      id: formData.id,
      fname: formData.name,
      lname: formData.username,
      email: formData.email,
      phone: phoneNumber,
      country: formData.country,
      address: formData.address,
      role: formData.role,
      company: formData.company,
      image: avatarPath
    }
    var res = await updateUser(sendData);

    if (res?.status == 200) {
      notification.success({
        placement: "topRight",
        description: "User has been updated successfully!",
        duration: 3,
      });

      if (user.lname == formData.username) {
        dispatch({
          type: UPDATE_USER,
          payload: sendData
        });
      }

      setTimeout(() => {
        navigate("/Users");
      }, 1000);
    }
    else {
      notification.error({
        placement: "topRight",
        description: res?.data.message,
        duration: 3,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setAvatarPath(result.data.filePath);
      }
    }
  }

  return (
    <div className="add-user-main w-100 h-100">
      <p>Edit User</p>
      <div className='add-user-div1 d-flex justify-content-start flex-column p-5 mx-5'>
        <div className='person-add-user-sub1-add-user-div1 d-flex justify-content-center position-relative mb-5'>
          <input type='file' className='personfile position-absolute' accept='image/*' onChange={onChange} />
          <img crossOrigin='*' src={avatarPath ? process.env.REACT_APP_URL + '/' + avatarPath : '/assets/common_user.png'} alt="none" className="person-add-user object-fit-cover" />
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
          <div className='d-flex flex-column'>
            <label>Name</label>
            <input className='normal-input' value={formData.name} type='text' name="name" onChange={handleData} />
          </div>
          <div className='sub2-subsub1-sub1-add-user-div1 d-flex flex-column position-relative'>
            <label>Username</label>
            <input className='normal-input' value={formData.username} type='text' name="username" onChange={handleData} disabled />
          </div>
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
          <div className='d-flex flex-column'>
            <label>Email</label>
            <input className='normal-input' value={formData.email} type='email' name="email" onChange={handleData} />
          </div>
          <div className='d-flex flex-column'>
            <label>Mobile No.</label>
            <div className='normal-input border-0 p-0'>
              <PhoneNumberInput
                value={phoneNumber}
                onChange={handleNumber}
              />
            </div>
          </div>
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
          <div className='d-flex flex-column position-relative'>
            <label>Country & City</label>
            <div className="normal-input p-0 border-0">
              <Select
                showSearch
                allowClear
                size={isMobile ? "small" : "large"}
                className="w-100"
                placeholder="City & Country"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={CountryData?.map((item) => ({
                  label: `${item.city}, ${item.country}`, value: `${item.city}, ${item.country}`
                }))}
                value={formData.country}
                onChange={(value) => setFormData({ ...formData, "country": value })}
              />
            </div>
          </div>
          <div className='d-flex flex-column'>
            <label>Address</label>
            <input className='normal-input' type='text' value={formData.address} name="address" onChange={handleData} />
          </div>
        </div>
        <div className='add-input-container d-flex justify-content-evenly mb-5 gap-3'>
          <div className='d-flex flex-column'>
            <label>User Type</label>
            <select className='normal-input' type='text' name="role" onChange={handleData} value={formData.role} >
              {role === UserRole.Admin && <option value={UserRole.Admin}>Super Admin</option>}
              {(role == UserRole.Admin || role == UserRole.Manager) && <option value={UserRole.Manager}>Admin</option>}
              <option value={UserRole.Client}>Client</option>
            </select>
          </div>
          <div className='d-flex flex-column'>
            <label>Company</label>
            <select className='normal-input' type='text' name="company" value={formData.company} onChange={handleData} >
              <option value=""></option>
              {location?.state?.companies && location?.state?.companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
            </select>
          </div>
        </div>
        <div className='subsub5-sub1-add-user-div1 d-flex justify-content-center' >
          <button type='button' className='me-5' onClick={() => navigate("/Users")}>Cancel</button>
          <button type='button' onClick={() => handleSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
