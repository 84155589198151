import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { commandSetting } from "../../../services/axios";

import "./SideBar.css";
import { formatDateTime, getDeviceImage, getFuelValue, getIOValue, showConfirmEx, sleep } from "../../../utils/globals";
import { cmdType } from "../../../common/common";
import { sendCommandMsg } from "../../../socket";

const ControlPanel = ({ selectedVehData, hidePanel, setLoadingMsg, setIsLoading, setKeepControlPanel }) => {
  const navigate = useNavigate();

  const [simState, setSimState] = useState(false);
  const [uploadImg, setUploadImg] = useState("/assets/Pic11.svg");
  const [uploadText, setUploadText] = useState("Upload Config");
  const [isUploadDialogShow, setIsUploadDialogShow] = useState(false);

  const handleEngine = (e, imei, name) => {
    let state = e.target.checked;
    setKeepControlPanel(true);
    showConfirmEx(`Are you sure to Turn ${state ? 'ON' : 'OFF'} Engine for ${name}?`)
      .then(async () => {
        setLoadingMsg("Toggling Engine status ...");
        setKeepControlPanel(false);
        setIsLoading(true);

        const sendData = {
          token: localStorage.getItem("token"),
          type: cmdType.EngineOn,
          params: { on: state ? "1" : "0" },
          devImei: imei,
        };
        const res = await commandSetting(sendData);
        if (res.status === 200) {
          sendCommandMsg(res.data.commandId);
          await sleep(10000);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }).catch(() => {
        setKeepControlPanel(false);
      });
  };

  const handlUpload = () => {
    setUploadImg("/assets/Pic12.svg");
    setUploadText("Update In Progres");
    setIsUploadDialogShow(!isUploadDialogShow);
    setTimeout(() => {
      setUploadImg("/assets/Pic11.svg");
      setUploadText("Upload Config");
    }, 2000);
  };

  const handleCommandsSettings = (tab = 0) => {
    navigate(`/CommandsSettings?tab=${tab}`, {
      state: {
        Name: selectedVehData?.vehicleName,
        device: selectedVehData?.deviceType + " " + selectedVehData?.deviceModel,
        deviceImei: selectedVehData?.deviceImei,
        deviceIccid: selectedVehData?.iccid,
        gsmNumbers: selectedVehData?.gsmNumbers,
      },
    });
    hidePanel();
  }

  const handleTelemetry = () => {
    navigate("/Telemetry", {
      state: {
        Name: selectedVehData?.vehicleName,
        device: selectedVehData?.deviceType,
        deviceModel: selectedVehData?.deviceModel,
        deviceImei: selectedVehData?.deviceImei,
      },
    });
    hidePanel();
  }

  const handleHistory = () => {
    navigate("/History", {
      state: { vehicleInfo: selectedVehData },
    });
    hidePanel();
  }

  return (
    <div className="position-relative">
      <img
        className="car-close-portal-side-bar"
        src="/assets/close.svg"
        alt="none"
        onClick={hidePanel}
      />
      <div className="car-portal-side-bar d-flex justify-content-center align-items-center">
        <div className="sub1-car-portal-side-bar bg-white">
          <div className="subsub1-sub1-car-portal-side-bar d-flex px-4 py-3 w-100 align-items-center justify-content-start gap-3">
            <img crossOrigin="*" src={selectedVehData?.company ? process.env.REACT_APP_URL + '/' + selectedVehData?.company.logo : "/assets/uber.png"} alt="none" />
            <p className="mb-0">{selectedVehData?.company?.name ?? "Uber"}</p>
          </div>
          <div className="subsub2-sub1-car-portal-side-bar d-flex align-items-center my-1 py-4 px-4">
            <img src={`/assets/${selectedVehData?.vehicleType}.svg`} alt="none" style={{ width: "5.8rem", maxHeight: "4rem" }} />
            <p className="ms-4 mb-0">
              {selectedVehData?.vehicleName}
            </p>
          </div>
          <div className="subsub3-sub1-car-portal-side-bar my-1 py-3 px-3">
            <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center justify-content-center">
              <img src="/assets/arrow.png" alt="none" />
              <p className="mb-0 ms-5" style={{ width: "11rem" }}>
                <strong className="d-block fw-medium" style={{ color: selectedVehData?.connected ? '#28A745' : '#FF0000' }}>
                  {selectedVehData?.connected ? 'Connected' : 'Not Connected'}&nbsp;
                </strong>
                <span>{formatDateTime(selectedVehData?.transferDate)}&nbsp;</span>
              </p>
            </div>
          </div>
          <div className="subsub3-sub1-car-portal-side-bar my-1 py-3 px-3">
            <div className="sub1-subsub3-sub1-car-portal-side-bar d-flex align-items-center justify-content-center">
              <img src="/assets/gps.svg" alt="none" />
              <p className="mb-0 ms-5" style={{ width: "11rem" }}>
                <strong className="d-block fw-medium">
                  {selectedVehData?.gpsFixed === 1 ?
                    <strong className="d-block fw-medium" style={{ color: "#28A745" }}>GPS Fixed</strong>
                    : <strong className="d-block fw-medium" style={{ color: "red" }}>GPS Not Fixed</strong>}
                </strong>
                <span>{formatDateTime(selectedVehData?.gpsFixedDate)}&nbsp;</span>
              </p>
            </div>
          </div>
          <div className={`subsub5-sub1-car-portal-side-bar ${selectedVehData?.vehicleType}`}>
            <img src={getDeviceImage(selectedVehData?.deviceModel)} alt="none" />
          </div>
          <div className="subsub6-sub1-car-portal-side-bar d-flex justify-content-center align-items-center">
            <p className="mb-0 py-1">
              IMEI: {selectedVehData?.deviceImei}
            </p>
          </div>
        </div>

        <div className="sub2-car-portal-side-bar h-100 d-flex flex-column justify-content-between align-items-center px-4 py-5">
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
              <img src="/assets/Pic1.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                {getIOValue(selectedVehData?.IOvalue, 16) / 1000} KM
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
              <img src={"/assets/Key.svg"} alt="none" />
              <p className="mt-4 mb-0 text-white text-center">
                Engine <span className={`engine-status ${selectedVehData?.ignition == 1 ? 'ON' : 'OFF'}`}>{selectedVehData?.ignition == 1 ? 'ON' : 'OFF'}</span>
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
              <img src="/assets/Pic3.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                {getFuelValue(selectedVehData?.fuel)} L
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
              <img src="/assets/Pic4.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                {selectedVehData?.temperature} C
              </p>
            </div>
          </div>
          <div className="horizontal-bar w-100"></div>
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer position-relative" style={{ width: "20%" }}>
              <img src="/assets/Pic5.svg" alt="none" />
              <div style={{ position: "absolute", left: "50%", top: "2rem", display: simState ? "block" : "none" }}>
                <img src="/assets/close.svg" alt="none" style={{ width: "1.5rem", height: "1.5rem", position: "absolute", right: "-0.7rem", top: "-0.7rem" }} onClick={() => setSimState(false)} />
                <div style={{ padding: ".8rem", width: "15.5rem", backgroundColor: "white", borderRadius: ".8rem" }}>
                  <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>SIM:</strong> {selectedVehData?.mobileNo}</p>
                  <p style={{ fontSize: "1rem", marginBottom: 0 }}><strong>ICCID:</strong> {selectedVehData?.iccid}</p>
                </div>
              </div>
              <img
                className="mt-4 mb-0"
                style={{ width: "1.5rem", height: "1.5rem" }}
                src="/assets/Group.svg"
                alt="none"
                onClick={() => setSimState(true)}
              />
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center" style={{ width: "20%" }}>
              <img src="/assets/Battery.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">
                {selectedVehData?.battery} %
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center" style={{ width: "20%" }}>
              <img src={`/assets/voltage${selectedVehData?.external <= 12000 ? '-low' : ''}.svg`} alt="none" />
              <p className="mt-4 mb-0 text-white">
                {Number((selectedVehData?.external / 1000).toFixed(1))} V
              </p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center" style={{ width: "20%" }}>
              <img src="/assets/Pic7.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">Camera</p>
            </div>

            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer" style={{ width: "20%" }}
              onClick={handleTelemetry}>
              <img src="/assets/telemetry.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">Telemetry</p>
            </div>
          </div>
          <div className="horizontal-bar w-100"></div>
          <div className="subsub1-sub2-car-portal-side-bar w-100 d-flex">
            <div className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center">
              <img src={selectedVehData?.dout1 == 1 ? "/assets/Pic2.svg" : "/assets/Pic13.svg"} alt="none" />
              <label className="car-switch mt-4">
                <input
                  type="checkbox"
                  checked={selectedVehData?.dout1 == 1}
                  onChange={(e) => {
                    handleEngine(e, selectedVehData?.deviceImei, selectedVehData?.vehicleName);
                  }}
                />
                <span className="car-slider">
                  <div className="car-on-off">
                    <p className="car-p car-p-on">ON</p>
                    <p className="car-p car-p-off" style={{ marginRight: ".5rem" }}>OFF</p>
                  </div>
                </span>
              </label>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={handleHistory}
            >
              <img src="/assets/Pic9.svg" alt="none" />
              <p className="mt-4 mb-0 text-white">History</p>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center cursor-pointer"
              onClick={() => handleCommandsSettings()}
            >
              <img src="/assets/commands.svg" alt="none" />
              <p className="text-center text-white mt-4 mb-0">Commands<br />Setting</p>
            </div>

            <div
              className="sub1-subsub1-sub2-car-portal-side-bar d-flex col-3 flex-column align-items-center"
              onClick={handlUpload}
            >
              <img src={uploadImg} alt="none" />
              <p
                className="mb-0 mt-4 text-center"
                style={{
                  color:
                    uploadText === "Upload Config"
                      ? "white"
                      : "#F89823",
                }}
              >
                {uploadText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
