import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal, notification } from 'antd';
import { showConfirmEx } from '../../../utils/globals';
import Search from 'antd/es/input/Search';
import { addRuleVehicles, updateGeofence } from '../../../services/axios';

const AssignVehicleZone = ({ isShowModal, onClose, geofence }) => {

    const dispatch = useDispatch();
    const devices = useSelector((state) => state.devicesList.devices);
    const [devicesData, setDevicesData] = useState(devices);

    const [checkAll, setCheckAll] = useState(false);
    const [ignoreDevices, setIgnoreDevices] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        setDevicesData(devices);
    }, [devices]);

    useEffect(() => {
        setIgnoreDevices(geofence?.ignoreDevices ?? []);
    }, [geofence]);

    const handleSearch = (e) => {
        setKeyword(e.target.value);
    }

    const handleCheck = (checked, item) => {
        if (checked) {
            setIgnoreDevices(ignoreDevices.filter(imei => imei != item.deviceImei));
        } else {
            setIgnoreDevices([...ignoreDevices, item.deviceImei]);
        }
    }

    const handleAssign = async () => {
        showConfirmEx(`Are You sure to Add selected vehicles to zone ${geofence?.name}?`)
            .then(async () => {
                const res = await updateGeofence(geofence._id, geofence.name, ignoreDevices, geofence.type, geofence.geometry);
                if (res.status == 200) {
                    notification.success({
                        description: "Add successfully!",
                    });
                    onClose(true, ignoreDevices);
                } else {
                    notification.error({
                        description: "Something went wrong!",
                    });
                }
            })
            .catch(() => {
            });
    }

    return (
        <Modal
            title={(
                <div className='position-relative text-center'>
                    <span className="fw-normal text-danger position-absolute top-0 start-0 mt-2 ms-4" style={{ textDecoration: "underline" }}>
                    Add Vehicles
                    </span>
                    {geofence ?
                        <span className='bg-danger text-white rounded-3 px-4 py-2' style={{ fontSize: "1.2rem" }}>
                            {geofence?.name}
                        </span> : <span>&nbsp;</span>
                    }
                </div>
            )}
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={800}
            style={{ maxWidth: "100%" }}
            className='assing-vehicle-modal'
            footer={false}
        >
            <div className='pt-4'>
                <div className='mb-3'>
                    <Search
                        size='large'
                        className='w-50'
                        placeholder='Plate No, IMEI No.'
                        onChange={handleSearch}
                    />
                </div>
                <div className="list-header py-3">
                    <p className='text-center'>
                        {/* <Checkbox /> */}
                    </p>
                    <p>Plate No.</p>
                    <p>Device</p>
                    <p>Model</p>
                    <p>IMEI No.</p>
                </div>
                <div className="list-body overflow-auto" style={{ minHeight: '25rem' }}>
                    {devicesData
                        ?.filter(item => item.deviceImei?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                            || item.vehicleName?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
                        ?.map((item, index) => (
                            <div key={index}>
                                <p className='text-center'>
                                    <Checkbox
                                        checked={!ignoreDevices?.includes(item?.deviceImei)}
                                        onChange={(e) => handleCheck(e.target.checked, item)}
                                    />
                                </p>
                                <p>{item?.vehicleName}</p>
                                <p>{item?.deviceType}</p>
                                <p>{item?.deviceModel}</p>
                                <p>{item?.deviceImei}</p>
                            </div>
                        ))}
                </div>
                <div className='mt-4 d-flex justify-content-end footer'>
                    <button className='tab-button' onClick={onClose}>Cancel</button>
                    <button className='tab-button ms-3' onClick={handleAssign}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default AssignVehicleZone;
