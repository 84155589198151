import { getAllDevices } from "../../services/axios";
import { GET_DEVICES, LOG_OUT } from "../store/types";

export const getDevices = (liveInfo = true) => async (dispatch) => {
  try {
    var response = await getAllDevices({ liveInfo });
    if (response.status === 401 || response.status === 403) {
      dispatch({
        type: LOG_OUT,
      });
    }
    if (response.status === 200) {
      dispatch({
        type: GET_DEVICES,
        payload: response.data,
        liveInfo: liveInfo
      });
    }
  } catch (error) {
  }
};
