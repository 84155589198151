import { useEffect, useRef, useState } from "react";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { Input } from "antd";
import 'react-phone-number-input/style.css'
import "./PhoneNumberInput.css";

const PhoneNumberInput = ({ value, readOnly = false, placeholder = "", onChange }) => {

    const inputRef = useRef(null);

    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        try {
            if (!value.startsWith("+")) {
                value = "+" + value;
            }
            const info = parsePhoneNumber(value);
            if (info) {
                setCountryCode("+" + info.countryCallingCode);
                setPhoneNumber(info.nationalNumber);
            }
        } catch (err) {}
    }, [value]);

    const handleChangeCountryCode = (code) => {
        if (onChange) {
            setCountryCode(code);
            onChange(code + "" + phoneNumber);
        }
    }
    
    const handleChangePhoneNumber = (num) => {
        if (onChange) {
            setPhoneNumber(num);
            onChange(countryCode + "" + num);
        }
    }

    const handleInputPhoneNumber = (e) => {
        if ("0123456789".indexOf(e.key) == -1 &&
            e.key != "Tab" && e.key != "Backspace" && e.key != "Delete" &&
            e.key != "ArrowLeft" && e.key != "ArrowRight" && !(e.ctrlKey && e.key == "v")
        ) {
            e.preventDefault();
        }
    }

    return (
        <div className="d-flex phone-number-input">
            <PhoneInput
                international
                defaultCountry="US"
                value={countryCode} 
                onChange={handleChangeCountryCode}
                onCountryChange={() => inputRef?.current?.focus()}
                readOnly
            />
            <Input
                ref={inputRef}
                bordered={false}
                value={phoneNumber}
                readOnly={readOnly}
                placeholder={placeholder}
                onKeyDown={handleInputPhoneNumber}
                onChange={e => handleChangePhoneNumber(e.target.value)}
            />
        </div>
    );
}

export default PhoneNumberInput;