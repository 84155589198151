import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { setDefaults, fromAddress, fromLatLng } from "react-geocode";
import MapContainer from '../commons/maps/MapContainer';
import { Button, Dropdown, Input, notification, Select, Tooltip } from 'antd';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { createGeofence, deleteGeofence, getGeofences, updateGeofence } from '../../services/axios';
import { showConfirmEx } from '../../utils/globals';
import AssignVehicleZone from './assignVehicle/AssignVehicleZone';
import { useMediaQuery } from '@mui/material';
import "./Geofence.css";

const Geofence = () => {

  const gm = JSON.parse(localStorage.getItem('gm') ? localStorage.getItem('gm') : {});
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [searchText, setSearchText] = useState(null);
  const [searchPoint, setSearchPoint] = useState(null);

  const [geofenceDropdownOpen, setGeofenceDropdownOpen] = useState(false);

  const [geofences, setGeofences] = useState([]);
  const [selectedGeoitem, setSelectedGeoitem] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newGeoitemName, setNewGeoitemName] = useState("");
  const [editingItem, setEditingItem] = useState(null);

  const [selectedGeoitem2, setSelectedGeoitem2] = useState(null);
  const [isShowVehicleModal, setIsShowVehicleModal] = useState(false);

  const [drawingType, setDrawingType] = useState("none");
  const [drawingGeometry, setDrawingGeometry] = useState(null);

  const [placeServices, setPlaceServices] = useState(null);
  const [predictions, setPredictions] = useState([]);

  const getList = async () => {
    const res = await getGeofences();
    if (res?.status == 200) {
      setGeofences(res.data.result);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (gm?.g) {
      setDefaults({ key: gm?.g, language: "en" });
    }
  }, [gm]);

  useEffect(() => {
    if (!geofenceDropdownOpen) {
      setIsCreatingNew(false);
      setEditingItem(null);
      setNewGeoitemName("");
    }
  }, [geofenceDropdownOpen]);

  useEffect(() => {
    if (selectedGeoitem) {
      // setDrawingType(selectedGeoitem.type);
      setDrawingType("none");
      setDrawingGeometry({
        type: selectedGeoitem.type,
        geometry: selectedGeoitem.geometry
      });
    } else {
      setDrawingType("none");
      setDrawingGeometry(null);
    }
  }, [selectedGeoitem]);


  const getPredictions = async (text) => {
    if (!placeServices || !text) {
      return;
    }

    const request = { input: text, sessionToken: placeServices.sessionToken };
    const response = await placeServices.autocomplete.getPlacePredictions(request);
    setPredictions(response.predictions);
  }

  const handleSearch = () => {
    if (!searchText) {
      return;
    }

    fromAddress(searchText)
      .then(({ results }) => {
        let pos = results[0].geometry.location;
        setSearchPoint(pos);
      })
      .catch(console.error);
  }
  useEffect(() => {
    handleSearch();
  }, [searchText]);

  const dropdownRef = useOutsideClick(() => {
    setGeofenceDropdownOpen(false);
  });

  const handleCreate = async () => {
    if (newGeoitemName.trim().length == 0) {
      notification.error({
        description: "Please input the zone name",
      });
      return;
    }
    const res = await createGeofence(newGeoitemName, [], drawingType, null);
    if (res.status === 200) {
      setGeofences([
        res.data.result,
        ...geofences
      ]);
      setSelectedGeoitem(res.data.result);
      setGeofenceDropdownOpen(false);
    } else {
      notification.error({
        description: "Error occured!",
      });
    }
  }
  const handleUpdate = async () => {
    if (newGeoitemName.trim().length == 0) {
      notification.error({
        description: "Please input the zone name",
      });
      return;
    }
    if (newGeoitemName == editingItem.name) {
      setGeofenceDropdownOpen(false);
      return;
    }

    const res = await updateGeofence(editingItem._id, newGeoitemName, editingItem.ignoreDevices, editingItem.type, editingItem.geometry);
    if (res.status === 200) {
      setGeofences(geofences.map(item => {
        if (item._id == editingItem._id) {
          return {
            ...item,
            name: newGeoitemName
          }
        } else {
          return item;
        }
      }));
      if (selectedGeoitem && editingItem._id == selectedGeoitem._id) {
        setSelectedGeoitem({ ...selectedGeoitem, name: newGeoitemName });
      }
      setGeofenceDropdownOpen(false);

      notification.success({
        description: "Zone has been updated successfully!",
      });
    } else {
      notification.error({
        description: "Error occured!",
      });
    }
  }

  const handleSave = async () => {
    if (selectedGeoitem == null) {
      return;
    }
    const res = await updateGeofence(selectedGeoitem._id, selectedGeoitem.name, selectedGeoitem.ignoreDevices, drawingGeometry.type, drawingGeometry.geometry);
    if (res.status == 200) {
      setGeofences(geofences.map(item => {
        if (item._id == selectedGeoitem._id) {
          return {
            ...item,
            type: drawingGeometry.type,
            geometry: drawingGeometry.geometry,
          }
        } else {
          return item;
        }
      }));
      setSelectedGeoitem({
        ...selectedGeoitem,
        type: drawingGeometry.type,
        geometry: drawingGeometry.geometry,
      });

      notification.success({
        description: "Zone has been updated successfully!",
      });
    } else {
      notification.error({
        description: "Error occured!",
      });
    }
  }
  const handleDelete = async (ditem, e) => {
    e.stopPropagation();

    if (ditem == null) {
      return;
    }
    showConfirmEx(`Are You sure to Delete the ${ditem.name}`)
      .then(async () => {
        const res = await deleteGeofence(ditem._id);
        if (res.status == 200) {
          if (selectedGeoitem && selectedGeoitem._id == ditem._id) {
            setSelectedGeoitem(null);
          }
          setGeofences(geofences.filter(item => item._id != ditem._id))

          notification.success({
            description: "Zone has been removed successfully!",
          });
        } else {
          notification.error({
            description: "Error occured!",
          });
        }
      }).catch(() => { });
  }

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key == 'Delete' && selectedGeoitem) {
        handleDelete(selectedGeoitem, e);
      }
    }
    document.addEventListener('keyup', onKeyPress);
    return () => {
      document.removeEventListener('keyup', onKeyPress);
    }
  }, [handleDelete]);

  const handleAddVehicle = async (item, e) => {
    e.stopPropagation();
    setSelectedGeoitem2(item);
    setIsShowVehicleModal(true);
  }

  const handleChangeDrawingType = async (type) => {
    if (drawingType == type) {
      setDrawingType("none");
    } else {
      setDrawingType(type);
    }
  }

  const handleUpdateGeometry = async (type, geometry) => {
    // console.log({ type, geometry });
    setDrawingGeometry({ type, geometry });
    setDrawingType("none");
  }

  const handleClickMap = async () => {
    if (selectedGeoitem != null && selectedGeoitem.geometry != drawingGeometry?.geometry) {
      showConfirmEx(`Current operation will be cancelled, continue?`)
        .then(async () => {
          setSelectedGeoitem(null);
        })
        .catch(() => { });
    } else {
      setSelectedGeoitem(null);
    }
  }

  return (
    <div className="map-container position-relative d-flex justify-content-center w-100 geofence-main">
      <div className="geofence-top-panel-wrapper">
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center bg-white px-5 py-3 mt-5 mt-sm-0 sub1-geofence-div1">
          <div className="d-flex flex-column justify-content-center align-items-center gap-3" id="dropdownRef" ref={dropdownRef}>
            <label>Select or Create Zone</label>
            <Dropdown
              open={geofenceDropdownOpen}
              getPopupContainer={() => document.getElementById('dropdownRef')}
              trigger={"click"}
              placement="bottom"
              dropdownRender={(menu) => {
                return (
                  <div className="d-flex flex-column bg-white px-3 py-3 geofence-dropdown-menu">
                    {isCreatingNew ?
                      <Input placeholder='New Geozone name'
                        value={newGeoitemName}
                        onChange={(e) => setNewGeoitemName(e.target.value)}
                        onPressEnter={() => setGeofenceDropdownOpen(false)}
                        onBlur={(e) => {
                          if (newGeoitemName.length > 0) {
                            handleCreate();
                          }
                        }}
                      /> :
                      <div className='d-flex justify-content-end py-2'>
                        <button className='tab-button gap-2'
                          onClick={() => setIsCreatingNew(true)}
                          style={{ padding: ".1rem 1rem", minWidth: "10rem", fontSize: "1.2rem" }}
                        >
                          <img src="/assets/addbtn.svg" alt="none" style={{ width: "1.4rem" }} />
                          <span>Create Zone</span>
                        </button>
                      </div>
                    }
                    {geofences.map((item, index) => (
                      editingItem == item ?
                        <Input placeholder='Geozone name'
                          value={newGeoitemName}
                          onChange={(e) => setNewGeoitemName(e.target.value)}
                          onPressEnter={() => setGeofenceDropdownOpen(false)}
                          onBlur={(e) => {
                            if (newGeoitemName.length > 0) {
                              handleUpdate();
                            }
                          }}
                        /> :
                        <div key={index} className='position-relative cursor-pointer px-3 py-2'
                          onClick={(e) => {
                            setSelectedGeoitem(item);
                            setGeofenceDropdownOpen(false);
                          }}>
                          {item.name}
                          <div className='position-absolute end-0 top-0 mt-1'>
                            <Tooltip title="Add Vehicles" placement='bottom'>
                              <img
                                src="/assets/addbtn2.svg" className='cursor-pointer me-3' alt="none" style={{ width: "1.5rem" }}
                                onClick={(e) => handleAddVehicle(item, e)}
                              />
                            </Tooltip>
                            <Tooltip title="Edit Zone Name" placement='bottom'>
                              <img
                                src="/assets/edit2.svg" className='cursor-pointer me-3'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setNewGeoitemName(item.name);
                                  setEditingItem(item);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete Zone" placement='bottom'>
                              <img
                                src="/assets/delete2.svg" className='cursor-pointer'
                                onClick={(e) => handleDelete(item, e)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                    ))}
                  </div>
                );
              }}
            >
              <Button onClick={() => setGeofenceDropdownOpen(!geofenceDropdownOpen)} className='d-flex align-items-center justify-content-center position-relative'>
                <span style={{ color: "#7A7D8B" }}>
                  {selectedGeoitem == null ? "Geozone" : selectedGeoitem.name}
                </span>
                <FontAwesomeIcon icon={faAngleDown} className='position-absolute top-0 end-0 mt-3 me-3' />
              </Button>
            </Dropdown>

            {(selectedGeoitem != null && selectedGeoitem.geometry != drawingGeometry?.geometry) &&
              <div className="tab-button d-flex justify-content-center align-items-center gap-3 py-1 mt-2" onClick={handleSave}>
                <button>
                  {selectedGeoitem.geometry ? 'Update Zone' : 'Save Zone'}
                </button>
              </div>
            }
          </div>
        </div>
      </div>

      {selectedGeoitem != null &&
        <div className='position-absolute start-0 ms-5 d-flex flex-column justify-content-center gap-5 geofence-drawings-panel'>
          <div className={`${drawingType == 'circle' && 'selected'}`}
            onClick={() => handleChangeDrawingType("circle")}
          >
            <img src="/assets/img1.png" alt="none" />
          </div>
          <div className={`${drawingType == 'rectangle' && 'selected'}`}
            onClick={() => handleChangeDrawingType("rectangle")}
          >
            <img src="/assets/img2.png" alt="none" />
          </div>
          <div className={`${drawingType == 'polygon' && 'selected'}`}
            onClick={() => handleChangeDrawingType("polygon")}
          >
            <img src="/assets/img3.png" alt="none" />
          </div>
        </div>
      }

      <AssignVehicleZone
        isShowModal={isShowVehicleModal}
        geofence={selectedGeoitem2}
        onClose={(result, ignoreDevices) => {
          if (result) {
            setGeofences(geofences.map(item => {
              if (item._id == selectedGeoitem2._id) {
                return {
                  ...item,
                  ignoreDevices
                }
              } else {
                return item;
              }
            }));
          }
          setSelectedGeoitem2(null);
          setIsShowVehicleModal(false);
        }}
      />

      <Select
        showSearch
        allowClear
        className={`position-absolute vehicle-search-input ${isMobile ? 'mobile' : ''}`}
        placeholder="Search Location"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={predictions?.map(({ place_id, description }) => ({
          label: description, value: description
        }))}
        value={searchText}
        onChange={(val) => setSearchText(val)}
        onSearch={(val) => getPredictions(val)}
      />


      {/* <Input
          style={{ borderColor: "#D01400" }}
          placeholder="Search Location"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onPressEnter={handleSearch}
        />
        {predictions.length > 0 && (
          <div className='place-predictions'>
            {predictions.map(({ place_id, description }) => {
              return (
                <div key={place_id} onClick={() => setSearchText(description)}>
                  {description}
                </div>
              );
            })}
          </div>
        )} */}

      <MapContainer
        point={searchPoint}
        isDrawing={true} drawingType={drawingType}
        geofences={geofences} selectedGeoitem={selectedGeoitem}
        onSelectGeoitem={item => setSelectedGeoitem(item)}
        onUpdateGeometry={handleUpdateGeometry}
        onClickMap={handleClickMap}
        setPlaceServices={setPlaceServices}
      />
    </div>
  );
};

export default Geofence;
